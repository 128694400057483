import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import { Dropdown, Option } from "./dropdown";
init('user_id');


const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');  
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const submit = () => {
    if (name && email && message) {
      const serviceId = 'service_t5rwr4n';
      const templateId = 'template_5dz06nb';
      const userId = 'bdVDf2MRzL-QRUfwN';
      const templateParams = {
          name,
          email,
          phone,
          message
      };

      emailjs.send(serviceId, templateId, templateParams, userId)
          .then(response => console.log(response))
          .then(error => console.log(error));

      setName('');
      setEmail('');
      setMessage('');
      setPhone('');
      setEmailSent(true);

      alert("Thank you for your message, we will be in touch in no time!");
    } else {
        alert('Please fill in all fields.');
    }
}
const isValidEmail = email => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

  return (
    <div style={{width:"500px", margin:"auto"}}>
      <div className="contact-header">
        <h1 style={{textAlign:"center",fontSize:"50px", fontWeight:"lighter"}}><strong>Contact Us</strong></h1>
        <div style={{borderTop:"1px solid #004EFF", borderBottom:"1px solid #004EFF", width:"100px",height:"5px", marginTop:"15px", marginBottom:"25px", marginLeft:"auto",marginRight:"auto"}}></div>
        <p>Interested in investing with ODC Capital? <br/>
          Submit the form below and we will get back to you!
        </p>
      </div>
      <div className='form-container'>
        <input className='input' type="text" placeholder="Name *" value={name} onChange={e => setName(e.target.value)} />
        <input className='input' type="email" placeholder="Email Address *" value={email} onChange={e => setEmail(e.target.value)} />
        <input className='input' type="phone" placeholder="Phone Number" value={phone} onChange={e => setPhone(e.target.value)} />
        {/* <Dropdown
        className="input"
        formLabel="Choose a service"
        buttonText="Send form"
        action="/"
      >
        <Option selected value="How much capital are you looking to invest?" />
        <Option value="Option 1" />
        <Option value="Option 2" />
        <Option value="Option 3" />
      </Dropdown> */}
        <textarea className='input' placeholder="Message *" value={message} onChange={e => setMessage(e.target.value)}></textarea>
        <button className='submit-btn' onClick={submit}>Submit</button>
        {/* <span className={emailSent ? 'visible' : null}>Thank you for your message, we will be in touch in no time!</span> */}
      </div>
    </div>
  );
};

export default Contact;