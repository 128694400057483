import Navbar from "../components/navbar";
import Footer from "../components/footer";
import background from "../images/giphy.gif";

export default function HomePage() {
    return (
        <div>
            <div style={{
                height:"100vh",
                backgroundImage: `url(${background})`,
                backgroundSize: "100vw 100vh"
            }}>
                <div style={{background: "rgba(0, 0, 0, 0.8)", height: "100%"}}>
                    <Navbar/>
                    <div className="tagline-content">
                        <h2 className="tagline">
                        The Future <br /> of Investing.
                        </h2>
                        <a href="/contact"><button className="about-btn" >Learn More</button></a>
                    </div>
                </div>
            <Footer/>
            </div>
        </div>
    )
}