import React from "react";
import "../App.css";

function Footer() {
  return (
    <div className="footer">
      <footer className="py-5 bg-dark fixed-bottom">
        <div style={{display:"flex",flexDirection:"row"}}>
          {/* <a className="ft-link" href="">Legal</a>
          <a className="ft-link" href="">Contact</a>
          <div className="vl"></div>
          <a className="ft-link" href="">FAQ</a> */}
          <p className="ft-link" style={{color:"white"}}>Legal</p>
          <div className="vl"></div>
          <p className="ft-link" style={{color:"white"}}>Contact</p>
        </div>
        <div className="copyright-container">
          <p className="copyright">
            Copyright &copy; ODC Capital 2022
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
