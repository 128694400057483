import * as React from 'react';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function AboutPage() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <div style={{backgroundColor:"transparent", height:"100%",position:"relative"}}>
        
            <div className="about-header">
                <div style={{background:"rgba(0, 0, 0, 0.7)", height:"50%"}}>
                    <Navbar/>
                    <h1>About Us</h1>
                </div>
            </div>
            <div className="about-content">
                <div className="odc-capital">
                    <h1 style={{textAlign:"center",fontSize:"50px", fontWeight:"lighter"}}><strong>ODC Capital</strong></h1>
                    <div style={{borderTop:"1px solid #004EFF", borderBottom:"1px solid #004EFF", width:"100px",height:"5px", marginTop:"25px", marginBottom:"25px", marginLeft:"auto",marginRight:"auto"}}></div>
                    <p>ODC Capital is a private partnership based in the United States. 
                        As a private partnership, our aim is to achieve alpha for its partners and build wealth by investing in cryptocurrencies, crypto assets and crypto related securities. 
                        This ownership structure incentivizes seeking alpha for our clients in risk-managed ways to provide careful stewardship of capital.
                        Due to our ownership structure, one must be a partner, either General or Limited, to invest with ODC Capital.
                        Feel free to contact us to inquire about becoming a partner.<br/> <br/>
                        By employing a healthy mixture of experience, trading algorithms and human capital, we can effectively mitigate downside risks across varying market conditions. 
                        ODC Capital will carefully choose a mix of investments in the following industries including (but not limited to): <br/>
                        </p>
                        <ul>          
                            <li>  DeFi </li>
                            <li>  NFTs </li>
                            <li>  Metaverse </li>
                            <li>  DAPPs </li>
                            <li>  Layer 1/2 Solutions </li>
                            <li>  Blockchain As A Software / Blockchain As A Service </li>
                        </ul>

                    {/* <div className="investment-container">
                        <div className="investment">
                            <h4>Long Term Investments</h4>
                            
                        </div>
                        <div className="investment">
                            <h4>Short Term Investments</h4>

                        </div>
                    </div> */}
                </div>
                <div className="partners-container">
                    <h1 style={{textAlign:"center",fontSize:"50px", fontWeight:"lighter"}}><strong>General Partners</strong></h1>
                    <div style={{borderTop:"1px solid #004EFF", borderBottom:"1px solid #004EFF", width:"100px",height:"5px", marginTop:"25px", marginBottom:"25px", marginLeft:"auto",marginRight:"auto"}}></div>
                    {/* <div>
                        <Box sx={{ width: '100%', color:"white" }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} onChange={handleChange} aria-label="legal tabs" centered>
                                    <Tab className='tab' label="Charles Rice" {...a11yProps(0)} />
                                    <Tab className='tab' label="Oddie Adkins" {...a11yProps(1)} />
                                    <Tab className='tab' label="Daniel Hart" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <div className="grid">
                                    <div>
                                        <img src={require("../../src/images/odc-logo.png")} alt="pic" className='partner-img' />
                                    </div>
                                    <br/>
                                    Charles’ passion for finance stems from a young age, when his father first introduced him to the idea of stocks and investing. 
                                    He took that passion to Howard University where he majored in Finance, graduating with Honors. 
                                    While at Howard, Charles had the opportunity to learn about active investing and was also introduced to technical analysis in his freshman year of college. 
                                    Charles has a passion for technical analysis and synthesizing complex ideas, forming well informed trading ideas, and finding the best way to express those ideas in the market while utilizing effective risk management. 
                                    Charles believes deeply in the "bottom up investing" approach and that in the new age of data, fundamental analysis is not only important but necessary to quality investing.
                                    But at the same time he also believes technical analysis can help ease some of the problems of the unsaleable practice of market timing. 
                                    More than just trading and investing Charles enjoys exercise in many forms, watching sports and learning about history, specifically topics around how economics affected, or were affected by, important geopolitical events such as wars or political uprisings.
                                </div>
                            </TabPanel>
                            <TabPanel  value={value} index={1}>
                                <img src={require("../../src/images/odc-logo.png")} alt="pic" className='partner-img' /> <br/>
                                Item Two
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <img src={require("../../src/images/odc-logo.png")} alt="pic" className='partner-img' /> <br/>
                            </TabPanel>
                        </Box>
                    </div> */}
                    <div>
                        <div className="partner">
                            <div className='grid'>
                                <div className='partner-img' >
                                    <div className="fndr-img"></div>
                                    <h3>Charles Rice</h3>
                                </div>
                                <p className='partner-bio'>Charles’ passion for trading and investments stems from a young age, when his father first introduced him to the idea of stocks and investing at the age of 8. 
                                    He took that passion to Howard University, where he majored in Finance graduating with Honors. 
                                    While at Howard Charles had the opportunity to learn about active investing and was also introduced to technical analysis in his freshman year of college. 
                                    Charles has a passion for technical analysis and synthesizing complex ideas, forming well informed trading ideas, and finding the best way to express those ideas in the market while utilizing effective risk management. 
                                    Charles believes deeply in the bottom up investing approach and that in the new age of data fundamental analysis is not only important but necessary to quality investing, but he also believes technical analysis can help ease some of the problems of the unsaleable practice of market timing. 
                                    More than just trading and investing Charles enjoys exercise in many forms, watching sports and learning about history, specifically topics around how economics affected or were affected by important geopolitical events such as wars or political uprisings. 
                                </p>
                            </div>
                        </div>
                        <div className="partner">
                            <div className='grid'>
                                <div className='partner-img' >
                                    <div className="fndr-img"></div>
                                    <h3>Oddie Adkins</h3>
                                </div>
                                <p className='partner-bio'>Charles’ passion for trading and investments stems from a young age, when his father first introduced him to the idea of stocks and investing at the age of 8. 
                                    He took that passion to Howard University, where he majored in Finance graduating with Honors. 
                                    While at Howard Charles had the opportunity to learn about active investing and was also introduced to technical analysis in his freshman year of college. 
                                    Charles has a passion for technical analysis and synthesizing complex ideas, forming well informed trading ideas, and finding the best way to express those ideas in the market while utilizing effective risk management. 
                                    Charles believes deeply in the bottom up investing approach and that in the new age of data fundamental analysis is not only important but necessary to quality investing, but he also believes technical analysis can help ease some of the problems of the unsaleable practice of market timing. 
                                    More than just trading and investing Charles enjoys exercise in many forms, watching sports and learning about history, specifically topics around how economics affected or were affected by important geopolitical events such as wars or political uprisings. 
                                </p>
                            </div>
                        </div>
                        <div className="partner">
                            <div className='grid'>
                                <div className='partner-img' >
                                    <div className="fndr-img"></div>
                                    <h3>Daniel Hart</h3>
                                </div>
                                <p className='partner-bio'>Charles’ passion for trading and investments stems from a young age, when his father first introduced him to the idea of stocks and investing at the age of 8. 
                                    He took that passion to Howard University, where he majored in Finance graduating with Honors. 
                                    While at Howard Charles had the opportunity to learn about active investing and was also introduced to technical analysis in his freshman year of college. 
                                    Charles has a passion for technical analysis and synthesizing complex ideas, forming well informed trading ideas, and finding the best way to express those ideas in the market while utilizing effective risk management. 
                                    Charles believes deeply in the bottom up investing approach and that in the new age of data fundamental analysis is not only important but necessary to quality investing, but he also believes technical analysis can help ease some of the problems of the unsaleable practice of market timing. 
                                    More than just trading and investing Charles enjoys exercise in many forms, watching sports and learning about history, specifically topics around how economics affected or were affected by important geopolitical events such as wars or political uprisings. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }