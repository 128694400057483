import Contact from '../components/contactform';
import Footer from '../components/footer';
import Navbar from '../components/navbar';

export default function ContactPage() {
    return (
        <div>
            <Navbar/>
            <Contact/>
            <Footer/>
        </div>
    )
}