import * as React from 'react';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Document, Page } from 'react-pdf';
import termsPDF from '../legal-docs/terms.pdf'

export default function LegalPage() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div>
            
            <div className="legal-header">
                <div style={{background: "rgba(0, 0, 0, 0.5)", height:"100%"}}>
                    <Navbar/>
                    <h1>LEGAL</h1>
                </div>
                <Document file={termsPDF}>
                    <Page pageNumber={1} />
                </Document>
            </div>
            <div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="legal tabs" centered>
                            <Tab label="Important Information" {...a11yProps(0)} />
                            <Tab label="Privacy Policy" {...a11yProps(1)} />
                            <Tab label="Terms and Conditions" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        
                    </TabPanel>
                    <TabPanel  value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Document file={termsPDF}>
                            <Page pageNumber={1} />
                        </Document>
                    </TabPanel>
                </Box>
            </div>
            <Footer/>
        </div>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }