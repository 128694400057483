import { NavLink } from "react-router-dom";
import "../App.css";

export default function Navbar() {
  return (
    <div>
      <nav className="nav">
        <NavLink to="/">
          <img
            src={require("../../src/images/odc-logo.png")}
            className="logo"
            alt="ODC Capital"
          />
        </NavLink>
      </nav>
    </div>
  );
}
