import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import LegalPage from "./pages/legal";
import ContactPage from "./pages/contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage className="page" />} />
          <Route path="/about" element={<AboutPage className="page" />} />
          <Route path="/legal" element={<LegalPage className="page" />} />
          <Route path="/contact" element={<ContactPage className="page" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
